import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { fadeUp, vshrink } from 'shared';

import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
    selector: 'app-simple-select-popup',
    templateUrl: './simple-select-popup.component.html',
    styleUrls: ['./simple-select-popup.component.scss'],
    animations: [fadeUp('popup'), vshrink('listItem'), vshrink('listHeader')],
})
export class SimpleSelectPopupComponent<TValue> extends BasePopupComponent<SimpleSelectPopupComponent<TValue>> {
    @Output() popupResult: EventEmitter<TValue> = new EventEmitter<TValue>();

    values: Array<TValue>;

    header?: string;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
        this.popupOptions.hasArrow = true;
    }
}
