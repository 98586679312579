import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, of } from 'rxjs';
import { filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import {
    IBlockReason,
    INamedBlockReason,
    INamedTaskType,
    IRole,
    ISortingModel,
    ISubjectType,
    ITask,
    ITaskPATCH,
    ITaskPOST,
    ITaskType,
    IUser,
    TaskApiService,
} from 'shared';

import { ReportControlRoute } from '../../models/control-route.model';
import { EntityAction, FilterableEntityAction } from '../../models/entity-action.model';
import { EntityEvent } from '../../models/entity-event.action';
import { BlockReasonPopupComponent } from '../../mtp-main/components/modals/block-reason-popup/block-reason-popup.component';
import { EntitySharePopupComponent } from '../../mtp-main/components/modals/entity-share-popup/entity-share-popup.component';
import { RoleManagementService } from '../../mtp-settings/shared/services/role-management.service';
import { LazyTranslateLoader } from '../../utils/lazy-translate-loader';
import { SnackbarComponent } from '../components/modals/snackbar/snackbar.component';
import { UserSelectPopupComponent } from '../components/modals/user-select-popup/user-select-popup.component';
import { ControlBarService } from './control-bar.service';
import { DomComponent, DomService } from './dom.service';
import { POIService } from './poi.service';
import { SocketService } from './socket.service';
import { AppStateService } from './state/app-state.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class TaskService {
    private readonly _tasks: BehaviorSubject<Array<ITask>> = new BehaviorSubject<Array<ITask>>([]);
    public readonly tasks: Observable<Array<ITask>> = this._tasks.asObservable();
    private readonly _taskTypes: BehaviorSubject<Array<INamedTaskType>> = new BehaviorSubject<Array<INamedTaskType>>(
        []
    );

    public readonly taskTypes: Observable<Array<INamedTaskType>> = this._taskTypes.asObservable();
    private readonly _blockReasons: BehaviorSubject<Array<INamedBlockReason>> = new BehaviorSubject<
        Array<INamedBlockReason>
    >([]);

    private readonly _events: Subject<EntityEvent<ITask>> = new Subject<EntityEvent<ITask>>();
    public readonly events: Observable<EntityEvent<ITask>> = this._events.asObservable();

    public readonly blockReasons: Observable<Array<INamedBlockReason>> = this._blockReasons.asObservable();
    private _assignPopup: DomComponent<UserSelectPopupComponent>;
    private roles: Array<IRole>;

    private constructor(
        private readonly tenantService: TenantService,
        private readonly tasksApi: TaskApiService,
        private readonly poiService: POIService,
        private readonly socketService: SocketService,
        private readonly userService: UserService,
        private readonly roleService: RoleManagementService,
        private readonly translate: TranslateService,
        private readonly domService: DomService,
        private readonly controlBarService: ControlBarService,
        private readonly appState: AppStateService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        // Clear data on logout
        this.appState.user.stream.pipe(filter((user) => !user)).subscribe((_) => {
            this._taskTypes.next([]);
            this._tasks.next([]);
            this._blockReasons.next([]);
        });
        // Refresh on socket connect
        this.appState.socketStatus.stream
            .pipe(
                filter((s) => s.status === 'CONNECTED'),
                // Wait for the user to be known
                switchMap(() =>
                    firstValueFrom(
                        this.appState.user.stream.pipe(
                            filter((user) => !!user),
                            timeout(3000)
                        )
                    )
                ),
                // Refresh everything
                switchMap(() => Promise.all([this.refreshTaskTypes(), this.refreshTasks(), this.refreshBlockReasons()]))
            )
            .subscribe();
    }

    //
    // Data fetching
    //

    public async refreshTaskTypes(): Promise<Array<ITaskType>> {
        // Get task types
        const config = await firstValueFrom(this.tenantService.config);
        const taskTypes = config.taskApi.taskTypes;
        // Wait for base translations to have loaded
        await firstValueFrom((this.translate.currentLoader as LazyTranslateLoader).translationsLoaded$);
        // Extract translations and append them
        taskTypes.forEach((type) => this.applyTranslationsForTaskType(type));
        // Map to named type
        const namedTaskTypes = taskTypes.map(TaskService.mapTaskTypeToNamedType);
        // Publish task types and return them
        this._taskTypes.next(namedTaskTypes);
        return taskTypes;
    }

    public async refreshBlockReasons(): Promise<Array<IBlockReason>> {
        // Get block reasons
        const config = await firstValueFrom(this.tenantService.config.pipe(take(1)));
        const blockReasons = config.taskApi.blockReasons;
        // Extract translations and append them
        blockReasons
            .filter((type) => typeof type.name === 'object')
            .forEach((type) => {
                Object.entries(type.name).forEach(([lang, name]) => {
                    this.translate.setTranslation(lang, { blockReason: { [type.id]: name } }, true);
                });
            });
        // Map to named type
        const namedBlockReasons = blockReasons.map((it) => ({
            id: it.id,
            name: typeof it.name === 'object' ? `blockReason.${it.id}` : it.name,
        }));
        // Publish block reasons and return them
        this._blockReasons.next(namedBlockReasons);
        return blockReasons;
    }

    public async refreshTasks(): Promise<Array<ITask>> {
        // Check permission
        if (!(await this.userService.checkPermission('Tasks.ReadTasks.*'))) return [];
        // Fetch tasks
        const tasks: Array<ITask> = await firstValueFrom(this.tasksApi.getTasks());
        // Emit new tasks
        this._tasks.next(tasks);
        // Return tasks
        return tasks;
    }

    async getDraftTasks(scheduleDate: number, sorting?: ISortingModel<ITask>): Promise<Array<ITask>> {
        // Check permission
        if (!(await this.userService.checkPermission('Tasks.ReadTasks.*'))) return [];
        // Fetch tasks
        return firstValueFrom(this.tasksApi.getTasks(true, scheduleDate, false, sorting));
    }

    //
    // Utilities
    //

    public updateTaskTypes(newTypes: Array<ITaskType>): void {
        const newNamedTypes = newTypes.map((task) => {
            this.applyTranslationsForTaskType(task);
            return TaskService.mapTaskTypeToNamedType(task);
        });
        const newIds = newTypes.map((taskType) => taskType.id);
        this._taskTypes.next([...this._taskTypes.value.filter((type) => !newIds.includes(type.id)), ...newNamedTypes]);
    }

    public hasTask(id: string): Observable<boolean> {
        return this.getTaskForId(id).pipe(map((task) => !!task));
    }

    public localUpdate = async (task: ITask): Promise<ITask | null> => {
        if (task.draft) return null;
        const tasks = await firstValueFrom(this._tasks);
        const currentTask = tasks.find((t) => t.id === task.id);
        if (!currentTask) return null;
        Object.assign(currentTask, task);
        this._tasks.next(tasks);
        this._events.next({ type: 'updated', entity: currentTask });
        return currentTask;
    };

    public localAdd = async (task: ITask): Promise<ITask | null> => {
        if (task.draft) return null;
        const tasks = await firstValueFrom(this._tasks);
        if (tasks.find((t) => t.id === task.id)) return null;
        tasks.push(task);
        this._tasks.next(tasks);
        this._events.next({ type: 'added', entity: task });
        return task;
    };

    public localRemove = async (taskId: string): Promise<ITask | null> => {
        const tasks = await firstValueFrom(this._tasks);
        const index = tasks.findIndex((t) => t.id === taskId);
        let task = null;
        if (index >= 0) task = tasks.splice(index, 1)[0];
        this._tasks.next(tasks);
        this._events.next({ type: 'removed', entity: task });
        return task;
    };

    public getTaskForId(entityId: string): Observable<ITask> {
        return this.tasks.pipe(map((tasks) => tasks.find((task) => task.id === entityId)));
    }

    public getTaskForIdSync(entityId: string): ITask {
        return this._tasks.value.find((task) => task.id === entityId);
    }

    public getTasksForIdsSync(entityIds: Array<string>): Array<ITask> {
        return this._tasks.value.filter((task) => entityIds.includes(task.id));
    }

    public getTaskTypeForId(id: string): Observable<INamedTaskType> {
        return this.taskTypes.pipe(map((its) => its.find((it) => it.id === id)));
    }

    public getBlockReasonForId(id: string): Observable<INamedBlockReason> {
        return this.blockReasons.pipe(map((its) => its.find((it) => it.id === id)));
    }

    public getTaskTypeForIdSync(id: string): INamedTaskType {
        return this._taskTypes.value.find((it) => it.id === id);
    }

    public getBlockReasonForIdSync(id: string): INamedBlockReason {
        return this._blockReasons.value.find((it) => it.id === id);
    }

    public getActiveTaskForUser(): Observable<ITask> {
        return combineLatest([this.appState.user.stream, this.tasks]).pipe(
            map(([user, tasks]) =>
                tasks.filter((t) => user != null && t.assigneeId === user.id && t.status === 'IN_PROGRESS')
            ),
            map((tasks) => (tasks.length ? tasks[0] : null))
        );
    }

    public createTask(task: ITaskPOST): Observable<ITask> {
        return (
            this.tasksApi
                .postTask(task)
                // Add task locally
                .pipe(switchMap((t) => this.localAdd(t).then(() => t)))
        );
    }

    public updateTask(task: ITaskPATCH): Observable<ITask> {
        return (
            this.tasksApi
                .patchTask(task.id, task)
                // Update task locally
                .pipe(switchMap((t) => this.localUpdate(t).then(() => t)))
        );
    }

    async setLinkedIssue(taskId: string, issueId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.patchTask(taskId, { issueId }));
        if (!task.issueId) task.issueId = null; // Make removal explicit
        await this.localUpdate(task);
        return task;
    }

    public buildActions = async (task: ITask): Promise<Array<EntityAction>> => {
        const actions: Array<EntityAction> = [];
        if (!task) return actions;

        // Dependencies
        const userId = this.appState.user.value?.id ?? null;

        // Cache the list of roles
        if (!this.roles)
            this.roleService.listRoles().then((result) => {
                this.roles = result;
            });

        // Order actions by priority
        return [
            await this._buildPickUpEntityAction(task, userId),
            await this._buildFinishEntityAction(task, userId),
            await this._buildApproveInspectionEntityAction(task, userId),
            await this._buildRejectInspectionEntityAction(task, userId),
            await this._buildDropEntityAction(task, userId),
            await this._buildBlockEntityAction(task, userId),
            await this._buildCancelEntityAction(task),
            await this._buildUnblockEntityAction(task, userId),
            await this._buildResumeEntityAction(task, userId),
            await this._buildAssignEntityAction(task),
            await this._buildAssignInspectionEntityAction(task),
            await this._buildShareEntityAction(task),
            await this._buildEditEntityAction(task),
        ].filter((a) => a.applicable);
    };

    public async assignTask(taskId: string, userId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.assignTask(taskId, userId));
        await this.localUpdate(task);
        return task;
    }

    public async unassignTask(taskId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.unassignTask(taskId));
        task.assigneeId = null; // We need to explicitly set this, so localUpdate can overwrite the existing value.
        await this.localUpdate(task);
        return task;
    }

    public async pickUpTask(taskId: string): Promise<ITask> {
        const activeTask = await firstValueFrom(this.getActiveTaskForUser());
        if (activeTask) throw Error('Attempted to pick up a task while another was still active for the current user.');
        const task = await firstValueFrom(this.tasksApi.pickUpTask(taskId));
        await this.localUpdate(task);
        return task;
    }

    public async finishTask(taskId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.finishTask(taskId));
        await this.localUpdate(task);
        return task;
    }

    public async approveTaskInspection(taskId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.approveTaskInspection(taskId));
        await this.localUpdate(task);
        return task;
    }

    public async rejectTaskInspection(taskId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.rejectTaskInspection(taskId));
        await this.localUpdate(task);
        return task;
    }

    public async blockTask(taskId: string, blockReasonId?: string, blockReasonNote?: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.blockTask(taskId, blockReasonId, blockReasonNote));
        await this.localUpdate(task);
        return task;
    }

    public async dropTask(taskId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.dropTask(taskId));
        await this.localUpdate(task);
        return task;
    }

    public async fetchDetails(id: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.getTask(id));
        await this.localUpdate(task);
        return task;
    }

    public async cancelTask(id: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.cancelTask(id));
        await this.localUpdate(task);
        return task;
    }

    public async unblockTask(id: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.unblockTask(id));
        await this.localUpdate(task);
        return task;
    }

    public async resumeTask(id: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.resumeTask(id));
        await this.localUpdate(task);
        return task;
    }

    async deleteDraftTask(id: string): Promise<ITask> {
        return firstValueFrom(this.tasksApi.deleteTask(id));
    }

    async setTaskType(id: string, type: ITaskType): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                typeId: type?.id,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async setSubjectType(id: string, type: ISubjectType): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                subjectTypeId: type?.id || null,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async setDescription(id: string, description: string): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                description,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async setInspectionRequired(id: string, inspectionRequired: boolean): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                inspectionRequired,
                inspectorId: inspectionRequired ? undefined : null,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async assignInspector(id: string, userId: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.assignInspector(id, userId));

        await this.localUpdate(task);
        return task;
    }

    async unassignInspector(id: string): Promise<ITask> {
        const task = await firstValueFrom(this.tasksApi.unassignInspector(id));

        await this.localUpdate(task);
        return task;
    }

    setUrgent(id: string, urgent: boolean): Promise<ITask> {
        return firstValueFrom(
            this.tasksApi.patchTask(id, {
                urgent,
            })
        );
    }

    async setFinishBefore(id: string, date: number | null): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                finishBeforeDate: date,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async setPickupAfter(id: string, date: number | null): Promise<ITask> {
        const task = await firstValueFrom(
            this.tasksApi.patchTask(id, {
                pickupAfterDate: date,
            })
        );
        await this.localUpdate(task);
        return task;
    }

    async publishDraftTask(id: string): Promise<ITask> {
        return firstValueFrom(this.tasksApi.publishTask(id));
    }

    private applyTranslationsForTaskType(type: ITaskType): void {
        if (type.name && typeof type.name !== 'object') return;
        Object.entries(type.name).forEach(([lang, name]) => {
            this.translate.setTranslation(lang, { taskType: { [type.id]: name } }, true);
        });
    }

    private async _buildBlockEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.block',
            onClick: ($event): void => {
                if ($event) $event.stopImmediatePropagation();
                this.domService.appendComponentTo<BlockReasonPopupComponent>(
                    'overlay-container',
                    BlockReasonPopupComponent,
                    {
                        taskId: task.id,
                    }
                );
            },
            icon: 'remove_circle_outline',
            disabled: false,
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'IN_PROGRESS' && task.status !== 'TODO') return false;
                const permBlockAll = await this.userService.checkPermission('Tasks.BlockTasks.All');
                const permBlockSelf = await this.userService.checkPermission('Tasks.BlockTasks.Self');
                return permBlockAll || (assignedToSelf && permBlockSelf);
            })(),
        };
    }

    private async _buildCancelEntityAction(task: ITask): Promise<FilterableEntityAction> {
        return {
            label: 'comp.task-list-item.action.cancel',
            onClick: async ($event): Promise<void> => {
                if ($event) $event.stopImmediatePropagation();
                await this.cancelTask(task.id);
                if (
                    await firstValueFrom(
                        this.controlBarService.controlRoute.pipe(map((route) => route.mode !== 'DETAIL'))
                    )
                ) {
                    this.showStatusChangeNotification('CANCEL');
                }
            },
            icon: 'icon-cancel',
            iconStyle: 'outlined',
            disabled: false,
            applicable:
                (await this.userService.checkPermission('Tasks.CancelTasks')) &&
                (task.status === 'BLOCKED' || task.status === 'TODO' || task.status === 'IN_PROGRESS'),
        };
    }

    private async _buildUnblockEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.unblock',
            onClick: async ($event): Promise<void> => {
                if ($event) $event.stopImmediatePropagation();
                await this.unblockTask(task.id);
            },
            icon: 'do_not_disturb_off',
            iconStyle: 'outlined',
            disabled: false,
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'BLOCKED') return false;
                const permUnblockAll = await this.userService.checkPermission('Tasks.UnblockTasks.All');
                const permUnblockSelf = await this.userService.checkPermission('Tasks.UnblockTasks.Self');
                return permUnblockAll || (assignedToSelf && permUnblockSelf);
            })(),
        };
    }

    private async _buildResumeEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.resume',
            onClick: async ($event): Promise<void> => {
                if ($event) $event.stopImmediatePropagation();
                await this.resumeTask(task.id);
                this.showStatusChangeNotification('RESUME');
            },
            icon: 'symbol-resume',
            iconStyle: 'outlined',
            disabled: false,
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'DONE') return false;
                const permResumeAll = await this.userService.checkPermission('Tasks.ResumeTasks.All');
                const permResumeSelf = await this.userService.checkPermission('Tasks.ResumeTasks.Self');
                return permResumeAll || (assignedToSelf && permResumeSelf);
            })(),
        };
    }

    private async _buildShareEntityAction(task: ITask): Promise<FilterableEntityAction> {
        return {
            label: 'comp.entity-share-popup.share',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                this.domService.appendComponentTo<EntitySharePopupComponent>(
                    'overlay-container',
                    EntitySharePopupComponent,
                    {
                        entity: task,
                        entityType: 'TASK',
                    }
                );
            },
            icon: 'icon-share',
            iconStyle: 'outlined',
            disabled: false,
            applicable: await this.userService.checkPermission('Tasks.ShareTasks'),
        };
    }

    private async _buildFinishEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.finish',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                await this.finishTask(task.id);
                if (
                    await firstValueFrom(
                        this.controlBarService.controlRoute.pipe(map((route) => route.mode === 'DETAIL'))
                    )
                ) {
                    this.controlBarService.popRoute();
                } else {
                    this.showStatusChangeNotification('FINISH');
                }
            },
            icon: 'icon-check',
            disabled: await (async (): Promise<boolean> =>
                task.status === 'TODO' && !!(await firstValueFrom(this.getActiveTaskForUser())))(),
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'IN_PROGRESS' && task.status !== 'TODO' && task.status !== 'INSPECTION_APPROVED')
                    return false;
                const permFinishAll = await this.userService.checkPermission('Tasks.FinishTasks.All');
                const permFinishSelf = await this.userService.checkPermission('Tasks.FinishTasks.Self');
                return permFinishAll || (assignedToSelf && permFinishSelf);
            })(),
        };
    }

    private async _buildApproveInspectionEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        return {
            label: 'comp.task-list-item.action.approveInspection',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                await this.approveTaskInspection(task.id);
            },
            disabled: false,
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'TO_BE_INSPECTED') return false;
                return (
                    userId === task.inspectorId && (await this.userService.checkPermission('Tasks.ApproveInspection'))
                );
            })(),
        };
    }

    private async _buildRejectInspectionEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        return {
            label: 'comp.task-list-item.action.rejectInspection',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                await this.rejectTaskInspection(task.id);
            },
            disabled: false,
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'TO_BE_INSPECTED') return false;
                return (
                    userId === task.inspectorId && (await this.userService.checkPermission('Tasks.RejectInspection'))
                );
            })(),
        };
    }

    private async _buildEditEntityAction(task: ITask): Promise<FilterableEntityAction> {
        return {
            label: 'comp.issue-list-item.action.edit',
            onClick: async () => {
                const controlRoute: ReportControlRoute = {
                    mode: 'REPORT',
                    reportMode: 'TASK',
                    updateId: task.id,
                    linkedEntity: task.issue,
                    step: 'LOCATION',
                    subjectType: this.tenantService.getSubjectTypeForIdSync(task.subjectTypeId),
                    taskType: this.getTaskTypeForIdSync(task.typeId),
                    description: task.description,
                    location: task.location,
                    linkedPOI: task.poiId ? this.poiService.getPOIForIdSync(task.poiId) : undefined,
                    assignee: await firstValueFrom(this.userService.getUser(task.assigneeId)),
                    finishBefore: task.finishBeforeDate,
                    pickUpAfter: task.pickupAfterDate,
                };
                if (this.router.url.startsWith('/map')) {
                    this.controlBarService.pushRoute(controlRoute);
                } else {
                    this.router.navigate(['/map'], { state: { controlRoute } });
                }
            },
            icon: 'icon-edit',
            iconStyle: 'outlined',
            disabled: !['TODO', 'IN_PROGRESS'].includes(task.status),
            applicable: await this.userService.checkPermission('Tasks.ManageTasks'),
        };
    }

    private async _buildPickUpEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.pickup',
            onClick: async ($event): Promise<void> => {
                $event.stopImmediatePropagation();
                // Check if we can undo this action
                const allowUndo = await (async (): Promise<boolean> => {
                    const permUndoPickUpAll = await this.userService.checkPermission('Tasks.DropTasks.All');
                    const permUndoPickUpSelf = await this.userService.checkPermission('Tasks.DropTasks.Self');
                    return permUndoPickUpAll || (assignedToSelf && permUndoPickUpSelf);
                })();
                // Pick up the task
                await this.pickUpTask(task.id);
                // If we're on the list view, navigate to the detail view
                if (
                    await this.controlBarService.controlRoute.pipe(
                        take(1),
                        map((route) => route.mode === 'LIST')
                    )
                ) {
                    this.controlBarService.pushRoute({
                        mode: 'DETAIL',
                        entityType: 'TASK',
                        entityId: task.id,
                    });
                }
                // Show "undo" snackbar for drop action
                if (allowUndo) {
                    const snackbar = this.domService.appendComponentTo<SnackbarComponent>(
                        'overlay-container',
                        SnackbarComponent,
                        {
                            text: this.translate.instant('comp.task-list-item.snackbar.pickup.onPickup'),
                            actions: [
                                {
                                    label: this.translate.instant('comp.task-list-item.snackbar.pickup.dismissAction'),
                                    onClick: () => snackbar.remove(),
                                },
                                {
                                    label: this.translate.instant('comp.task-list-item.snackbar.pickup.undoAction'),
                                    primary: true,
                                    onClick: async (): Promise<void> => {
                                        // Close snackbar
                                        snackbar.remove();
                                        // Drop the task again
                                        await this.dropTask(task.id);
                                        // Pop control route if we are currently on detail view
                                        const detailViewActive = await firstValueFrom(
                                            this.controlBarService.controlRoute.pipe(
                                                take(1),
                                                map((route) => route.mode === 'DETAIL')
                                            )
                                        );
                                        if (detailViewActive) this.controlBarService.popRoute();
                                        // Show drop confirmation
                                        this.domService.appendComponentTo<SnackbarComponent>(
                                            'overlay-container',
                                            SnackbarComponent,
                                            {
                                                text: this.translate.instant(
                                                    'comp.task-list-item.snackbar.pickup.undoConfirm'
                                                ),
                                            }
                                        );
                                    },
                                },
                            ],
                        }
                    );
                }
            },
            icon: 'play_arrow',
            iconStyle: 'outlined',
            disabled: await firstValueFrom(this.getActiveTaskForUser().pipe(map((t) => !!t))),
            applicable: await (async (): Promise<boolean> => {
                if (task.status !== 'TODO') return false;
                const permPickUpAll = await this.userService.checkPermission('Tasks.PickUpTasks.All');
                const permPickUpSelf = await this.userService.checkPermission('Tasks.PickUpTasks.Self');
                const permPickUpUnassigned = await this.userService.checkPermission('Tasks.PickUpTasks.Unassigned');
                return (
                    permPickUpAll || (!task.assigneeId && permPickUpUnassigned) || (permPickUpSelf && assignedToSelf)
                );
            })(),
        };
    }

    private async _buildDropEntityAction(task: ITask, userId: string): Promise<FilterableEntityAction> {
        const assignedToSelf = task.assigneeId && task.assigneeId === userId;
        return {
            label: 'comp.task-list-item.action.drop',
            onClick: ($event): void => {
                $event.stopImmediatePropagation();
                this.dropTask(task.id);
            },
            icon: 'icon-pause',
            iconStyle: 'outlined',
            disabled: false,
            applicable:
                task.status === 'IN_PROGRESS' &&
                (((await this.userService.checkPermission('Tasks.DropTasks.Self')) && assignedToSelf) ||
                    (await this.userService.checkPermission('Tasks.DropTasks.All'))),
        };
    }

    private async _buildAssignEntityAction(task: ITask): Promise<FilterableEntityAction> {
        return {
            label: 'comp.task-list-item.action.assign',
            onClick: ($event): void => {
                $event.stopImmediatePropagation();
                if (this._assignPopup) {
                    this._assignPopup.remove();
                } else {
                    this.buildAssignPopup(
                        $event,
                        task,
                        (user: IUser) => this.assignTask(task.id, user.id),
                        () => this.unassignTask(task.id)
                    );
                }
            },
            icon: 'icon-person',
            iconStyle: 'outlined',
            disabled: false,
            applicable: await this.userService.checkPermission('Tasks.AssignTasks'),
        };
    }

    private async _buildAssignInspectionEntityAction(task: ITask): Promise<FilterableEntityAction> {
        return {
            label: 'comp.task-list-item.action.assignInspection',
            onClick: ($event): void => {
                $event.stopImmediatePropagation();
                if (this._assignPopup) {
                    this._assignPopup.remove();
                } else {
                    this.buildAssignPopup(
                        $event,
                        task,
                        (user: IUser) => this.assignInspector(task.id, user.id),
                        () => this.unassignInspector(task.id),
                        (user) => {
                            const permissions = this.roles
                                .filter((role) => user.roleIds.includes(role.id))
                                .reduce(
                                    (acc, role) => [...acc, ...role.permissions.map((permission) => permission.id)],
                                    [] as Array<string>
                                );
                            return (
                                permissions.includes('Tasks.ApproveInspection') ||
                                permissions.includes('Tasks.RejectInspection')
                            );
                        }
                    );
                }
            },
            icon: 'icon-people',
            iconStyle: 'outlined',
            disabled: false,
            applicable: await this.userService.checkPermission('Tasks.AssignTasks'),
        };
    }

    private buildAssignPopup(
        $event: MouseEvent,
        task: ITask,
        assignAction: (user: IUser) => Promise<ITask>,
        unassignAction: () => Promise<ITask>,
        filterOnUser: (user: IUser) => boolean = null
    ): void {
        const filterOnTask = (user: IUser): Observable<boolean> =>
            this.tasks.pipe(
                map(
                    (tasks) =>
                        !task.urgent ||
                        tasks.filter(
                            (_task) =>
                                _task.assigneeId === user.id &&
                                _task.urgent &&
                                (_task.status === 'TODO' || _task.status === 'IN_PROGRESS')
                        ).length === 0
                )
            );

        this._assignPopup = this.domService.appendComponentTo<UserSelectPopupComponent>(
            'overlay-container',
            UserSelectPopupComponent,
            {
                padding: 20,
                origin: [$event.clientX, $event.clientY],
                _userFilter: (user: IUser) =>
                    filterOnUser
                        ? combineLatest([of(filterOnUser(user)), filterOnTask(user)]).pipe(map(([a, b]) => a && b))
                        : filterOnTask(user),
                get userFilter() {
                    return this._userFilter;
                },
                set userFilter(value) {
                    this._userFilter = value;
                },
                hideFiltered: false,
            }
        );
        this._assignPopup.component.popupResult
            .pipe(
                tap(() => {
                    if (this._assignPopup) this._assignPopup.remove();
                }),
                switchMap((user: IUser) =>
                    this.socketService.disableUI.pipe(
                        take(1),
                        map((disableUI) => [disableUI, user] as [boolean, IUser])
                    )
                ),
                filter(([disableUI]) => !disableUI),
                switchMap(([, user]) => (user?.id ? assignAction(user) : unassignAction()))
            )
            .subscribe();
        this._assignPopup.on('remove', () => (this._assignPopup = null));
    }

    //
    // Actions
    //

    private showStatusChangeNotification(type: 'FINISH' | 'RESUME' | 'CANCEL'): void {
        const snackbar = this.domService.appendComponentTo<SnackbarComponent>('overlay-container', SnackbarComponent, {
            text: this.translate.instant(`misc.statusChange.task.${type.toLowerCase()}`),
            actions: [
                {
                    label: this.translate.instant('misc.statusChange.actions.dismiss'),
                    onClick: () => snackbar.remove(),
                },
            ],
        });
    }

    private static mapTaskTypeToNamedType(type: ITaskType): INamedTaskType {
        return {
            id: type.id,
            name: typeof type.name === 'object' ? `taskType.${type.id}` : type.name,
            iconId: type.iconId,
            archived: type.archived,
        };
    }
}
