export function ordinal(locale: string, n: number): string {
    switch (locale) {
        case 'en': {
            if (n >= 11 && n <= 13) return `${n}th`;
            switch (n % 10) {
                case 1:
                    return `${n}st`;
                case 2:
                    return `${n}nd`;
                case 3:
                    return `${n}rd`;
            }
            return `${n}th`;
        }
        case 'nl': {
            return `${n}e`;
        }
        default:
            return n.toString(10);
    }
}
