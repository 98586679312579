<div class="popup-arrow"></div>
<app-context-list>
    <app-context-list-header *ngIf="header">{{ header | translate }}</app-context-list-header>
    <div *ngFor="let value of values" @listItem>
        <app-context-list-item
            appFocusable
            appFocusOnHover
            (click)="popupResult.emit(value)"
            (keydown.enter)="popupResult.emit(value)"
        >
            {{ value }}
        </app-context-list-item>
    </div>
</app-context-list>
