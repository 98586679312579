import { HttpEvent, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';

import {
    FilterEntityType,
    IBuilding,
    IBuildingPOST,
    IBuildingPUT,
    IComment,
    IExternalSortingModel,
    IFilterConfigurationModel,
    IFilterModel,
    IImage,
    IIssue,
    IIssuePATCH,
    IIssuePOST,
    IIssueType,
    IIssueTypePOST,
    IIssueTypePUT,
    IJob,
    IJobPOST,
    IJobPUT,
    IJobSheet,
    IJobSheetFinalizeResponse,
    IPOI,
    IPOICategory,
    IPOICategoryPOST,
    IPOICategoryPUT,
    IPOIPATCH,
    IPOIPOST,
    IPaginatedTableResponse,
    ISharedEntity,
    ISortingModel,
    ISubjectType,
    ISubjectTypePOST,
    ISubjectTypePUT,
    ITask,
    ITaskApiConfig,
    ITaskPATCH,
    ITaskPOST,
    ITaskType,
    ITaskTypePOST,
    ITaskTypePUT,
    POIFilter,
    Pagination,
    TViewPortPolygon,
} from '../../models';
import { EntityFilters, PaginatedApiBaseService } from './paginated-api-base.service';

export type ApiResponseStatus<T> =
    | {
          status: 'completed';
          message: T;
      }
    | {
          status: 'progress';
          message: number;
      }
    | {
          status: 'ignored';
          message: string;
      };

@Injectable({ providedIn: 'root' })
export class TaskApiService extends PaginatedApiBaseService {
    constructor() {
        super('taskApi');
    }

    // CRUD
    public getIssues(includeArchived?: boolean, includeDraft?: boolean): Observable<IIssue[]> {
        const params = TaskApiService.getArchivedDraftParams(includeArchived, includeDraft);
        const defaultSorting: ISortingModel<IIssue> = { property: 'status', direction: 'descending', order: 1 };

        return this.getApiUrl('/issues').pipe(
            this.paginatedHttpGet<IIssue, IIssue>(null, null, [defaultSorting], null, null, params),
            map((response) => response.data)
        );
    }

    public getIssuesPaginated<TableModel>(
        pagination: Pagination,
        filters?: EntityFilters,
        sorting?: Array<ISortingModel<TableModel>>,
        filterPreset?: string,
        includeArchived?: boolean,
        includeDraftOnly?: boolean,
        viewport?: TViewPortPolygon
    ): Observable<IPaginatedTableResponse<IIssue>> {
        const params = TaskApiService.getArchivedDraftParams(includeArchived, includeDraftOnly);
        return this.getApiUrl('/issues').pipe(
            this.paginatedHttpGet<IIssue, TableModel>(pagination, filters, sorting, filterPreset, viewport, params)
        );
    }

    public getIssueFilters<TFilterModel>(): Observable<TFilterModel> {
        return this.getApiUrl('/issues/filters').pipe(mergeMap((url) => this.http.get<TFilterModel>(url)));
    }

    public getIssue(issueId: string): Observable<IIssue> {
        return this.getApiUrl('/issues/' + issueId).pipe(mergeMap((url) => this.http.get<IIssue>(url)));
    }

    public postIssue(issue: IIssuePOST): Observable<IIssue> {
        return this.getApiUrl('/issues').pipe(mergeMap((url) => this.http.post<IIssue>(url, issue)));
    }

    public exportIssues(type: string, language: string, ids: string[]): Observable<Blob> {
        const headers = new HttpHeaders().append('Accept-Language', language);

        return this.getApiUrl(`/issues/export/${type}`).pipe(
            mergeMap((url) => this.http.put(url, { ids }, { responseType: 'blob', headers })),
            tap((blob: Blob) => saveAs(blob, `issue-overview-${Date.now()}.csv`))
        );
    }

    public getIssueTypes(): Observable<IIssueType[]> {
        return this.getApiUrl('/issuetypes').pipe(mergeMap((url) => this.http.get<IIssueType[]>(url)));
    }

    public postIssueType(type: IIssueTypePOST): Observable<IIssueType> {
        return this.getApiUrl('/issuetypes').pipe(mergeMap((url) => this.http.post<IIssueType>(url, type)));
    }

    public deleteIssueType(issueTypeId: string): Observable<IIssueType> {
        return this.getApiUrl(`/issuetypes/${issueTypeId}`).pipe(mergeMap((url) => this.http.delete<IIssueType>(url)));
    }

    public putIssueType(issueTypeId: string, issueType: IIssueTypePUT): Observable<IIssueType> {
        return this.getApiUrl(`/issuetypes/${issueTypeId}`).pipe(
            mergeMap((url) => this.http.put<IIssueType>(url, issueType))
        );
    }

    public archiveIssueType(issueTypeId: string): Observable<IIssueType> {
        return this.getApiUrl(`/issuetypes/${issueTypeId}/archive`).pipe(
            mergeMap((url) => this.http.put<IIssueType>(url, issueTypeId))
        );
    }

    public getTasks(
        includeDraft = false,
        scheduleDate?: number,
        includeArchived?: boolean,
        sorting?: ISortingModel<ITask>
    ): Observable<ITask[]> {
        let params = TaskApiService.getArchivedDraftParams(includeArchived, includeDraft);
        if (typeof scheduleDate === 'number') params = params.append('scheduleDate', scheduleDate.toString());
        sorting ??= { property: 'status', direction: 'ascending', order: 1 };

        return this.getApiUrl('/tasks').pipe(
            this.paginatedHttpGet<ITask, ITask>(null, null, [sorting], null, null, params),
            map((response) => response.data)
        );
    }

    public getTasksPaginated<TableModel>(
        pagination: Pagination,
        filters: EntityFilters,
        sorting: Array<ISortingModel<TableModel>>,
        filterPreset?: string,
        includeArchived?: boolean,
        includeDraft?: boolean,
        viewport?: TViewPortPolygon
    ): Observable<IPaginatedTableResponse<ITask>> {
        const params = TaskApiService.getArchivedDraftParams(includeArchived, includeDraft);
        return this.getApiUrl('/tasks').pipe(
            this.paginatedHttpGet<ITask, TableModel>(pagination, filters, sorting, filterPreset, viewport, params)
        );
    }

    public getTaskFilters<TFilterModel>(): Observable<TFilterModel> {
        return this.getApiUrl('/tasks/filters').pipe(mergeMap((url) => this.http.get<TFilterModel>(url)));
    }

    public getTask(taskId: string): Observable<ITask> {
        return this.getApiUrl('/tasks/' + taskId).pipe(mergeMap((url) => this.http.get<ITask>(url)));
    }

    public postTask(task: ITaskPOST): Observable<ITask> {
        return this.getApiUrl('/tasks').pipe(mergeMap((url) => this.http.post<ITask>(url, task)));
    }

    public exportTasks(type: string, language: string, ids: string[]): Observable<Blob> {
        const headers = new HttpHeaders().append('Accept-Language', language);

        return this.getApiUrl(`/tasks/export/${type}`).pipe(
            mergeMap((url) => this.http.put(url, { ids }, { responseType: 'blob', headers })),
            tap((blob: Blob) => saveAs(blob, `task-overview-${Date.now()}.csv`))
        );
    }

    public getTaskTypes(): Observable<ITaskType[]> {
        return this.getApiUrl('/tasktypes').pipe(mergeMap((url) => this.http.get<ITaskType[]>(url)));
    }

    public postTaskType(type: ITaskTypePOST): Observable<ITaskType> {
        return this.getApiUrl('/tasktypes').pipe(mergeMap((url) => this.http.post<ITaskType>(url, type)));
    }

    public deleteTaskType(taskTypeId: string): Observable<ITaskType> {
        return this.getApiUrl(`/tasktypes/${taskTypeId}`).pipe(mergeMap((url) => this.http.delete<ITaskType>(url)));
    }

    public putTaskType(taskTypeId: string, taskType: ITaskTypePUT): Observable<ITaskType> {
        return this.getApiUrl(`/tasktypes/${taskTypeId}`).pipe(
            mergeMap((url) => this.http.put<ITaskType>(url, taskType))
        );
    }

    public archiveTaskType(taskTypeId: string): Observable<ITaskType> {
        return this.getApiUrl(`/tasktypes/${taskTypeId}/archive`).pipe(
            mergeMap((url) => this.http.put<ITaskType>(url, taskTypeId))
        );
    }

    public getSubjectTypes(): Observable<ISubjectType[]> {
        return this.getApiUrl('/subjecttypes').pipe(mergeMap((url) => this.http.get<ISubjectType[]>(url)));
    }

    public postSubjectType(type: ISubjectTypePOST): Observable<ISubjectType> {
        return this.getApiUrl('/subjecttypes').pipe(mergeMap((url) => this.http.post<ISubjectType>(url, type)));
    }

    public deleteSubjectType(subjectTypeId: string): Observable<ISubjectType> {
        return this.getApiUrl(`/subjecttypes/${subjectTypeId}`).pipe(
            mergeMap((url) => this.http.delete<ISubjectType>(url))
        );
    }

    public putSubjectType(subjectTypeId: string, subjectType: ISubjectTypePUT): Observable<ISubjectType> {
        return this.getApiUrl(`/subjecttypes/${subjectTypeId}`).pipe(
            mergeMap((url) => this.http.put<ISubjectType>(url, subjectType))
        );
    }

    public archiveSubjectType(subjectTypId: string): Observable<ISubjectType> {
        return this.getApiUrl(`/subjecttypes/${subjectTypId}/archive`).pipe(
            mergeMap((url) => this.http.put<ISubjectType>(url, subjectTypId))
        );
    }

    public postPOI(poi: IPOIPOST): Observable<IPOI> {
        return this.getApiUrl('/pois').pipe(mergeMap((url) => this.http.post<IPOI>(url, poi)));
    }

    public patchPOI(poi: IPOIPATCH): Observable<IPOI> {
        const { description, categoryId, location, buildingId } = poi;
        return this.getApiUrl('/pois/' + poi.id).pipe(
            mergeMap((url) => this.http.patch<IPOI>(url, { description, categoryId, location, buildingId }))
        );
    }

    public archivePOI(poiId: string): Observable<IPOI> {
        return this.getApiUrl(`/pois/${poiId}/archive`).pipe(mergeMap((url) => this.http.put<IPOI>(url, poiId)));
    }

    public deletePOI(poi: IPOI): Observable<void> {
        return this.getApiUrl('/pois/' + poi.id).pipe(mergeMap((url) => this.http.delete<void>(url)));
    }

    public getPOIs(filters: POIFilter = {}): Observable<IPOI[]> {
        // Stringify filter values
        const params = Object.entries(filters).reduce(
            (
                acc: {
                    [key: string]: string;
                },
                e
            ) => ((acc[e[0]] = e[1].toString()), acc),
            {}
        );
        // Send request
        return this.getApiUrl('/pois').pipe(mergeMap((url) => this.http.get<IPOI[]>(url, { params })));
    }

    public getPOICategories(): Observable<IPOICategory[]> {
        return this.getApiUrl('/poiCategories').pipe(mergeMap((url) => this.http.get<IPOICategory[]>(url)));
    }

    public postPOICategory(category: IPOICategoryPOST): Observable<IPOICategory> {
        return this.getApiUrl('/poiCategories').pipe(mergeMap((url) => this.http.post<IPOICategory>(url, category)));
    }

    public deletePOICategory(categoryId: string): Observable<IPOICategory> {
        return this.getApiUrl(`/poiCategories/${categoryId}`).pipe(
            mergeMap((url) => this.http.delete<IPOICategory>(url))
        );
    }

    public putPOICategory(categoryId: string, category: IPOICategoryPUT): Observable<IPOICategory> {
        return this.getApiUrl(`/poiCategories/${categoryId}`).pipe(
            mergeMap((url) => this.http.put<IPOICategory>(url, category))
        );
    }

    public getConfig(): Observable<ITaskApiConfig> {
        return this.getApiUrl('/config').pipe(mergeMap((url) => this.http.get<ITaskApiConfig>(url)));
    }

    public patchTask(taskId: string, patch: Partial<ITaskPATCH>): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}`).pipe(mergeMap((url) => this.http.patch<ITask>(url, patch)));
    }

    public patchIssue(issueId: string, patch: Partial<IIssuePATCH>): Observable<IIssue> {
        return this.getApiUrl(`/issues/${issueId}`).pipe(mergeMap((url) => this.http.patch<IIssue>(url, patch)));
    }

    public postIssueImage(issueId: string, file: File): Observable<HttpEvent<IImage>> {
        const formData = new FormData();
        formData.append('image', file);
        return this.getApiUrl(`/issues/${issueId}/images`).pipe(
            mergeMap((url) => this.http.post<IImage>(url, formData, { reportProgress: true, observe: 'events' }))
        );
    }

    public getJobs(scheduleDate: number): Observable<IJob[]> {
        return this.getApiUrl('/jobs').pipe(
            mergeMap((url) =>
                this.http.get<IJob[]>(url, {
                    params: {
                        scheduleDate: scheduleDate.toString(10),
                    },
                })
            )
        );
    }

    public postJobs(jobs: Array<Partial<IJobPOST>>): Observable<Array<IJob>> {
        return this.getApiUrl('/jobs', 'v2').pipe(mergeMap((url) => this.http.post<Array<IJob>>(url, jobs)));
    }

    public putJob(jobId: string, job: IJobPUT): Observable<IJob> {
        return this.getApiUrl(`/jobs/${jobId}`).pipe(mergeMap((url) => this.http.put<IJob>(url, job)));
    }

    public getJobSheets(scheduleDate: number): Observable<IJobSheet[]> {
        return this.getApiUrl('/jobsheets').pipe(
            mergeMap((url) =>
                this.http.get<IJobSheet[]>(url, {
                    params: {
                        scheduleDate: scheduleDate.toString(10),
                    },
                })
            )
        );
    }

    public generateJobSheets(
        scheduleDate: number,
        startingPointPoiId: string,
        workerTimeCapacity: number
    ): Observable<IJobSheet[]> {
        return this.getApiUrl(`/jobsheets/generate`, 'v2').pipe(
            mergeMap((url) =>
                this.http.post<IJobSheet[]>(url, {
                    scheduleDate,
                    startingPointPoiId,
                    workerTimeCapacity,
                })
            )
        );
    }

    public removeJobSheets(scheduledDate: number): Observable<IJobSheet[]> {
        return this.getApiUrl(`/jobsheets/cancel`, 'v2').pipe(
            mergeMap((url) =>
                this.http.delete<IJobSheet[]>(url, {
                    params: {
                        scheduledDate,
                    },
                })
            )
        );
    }

    public finalizeJobSheets(scheduledDate: number): Observable<IJobSheetFinalizeResponse> {
        return this.getApiUrl(`/jobsheets/finalize`, 'v2').pipe(
            mergeMap((url) =>
                this.http.post<IJobSheetFinalizeResponse>(url, {
                    scheduledDate,
                })
            )
        );
    }

    // General Actions
    public generateShareUrl(
        itemId: string,
        itemType: 'TASK' | 'ISSUE',
        expiresAt: number,
        allowFinish: boolean
    ): Observable<ISharedEntity> {
        return this.getApiUrl(`/shareLinks`).pipe(
            mergeMap((url) => this.http.post<ISharedEntity>(url, { itemId, itemType, expiresAt, allowFinish }))
        );
    }

    public updateShareUrl(shareId: string, expiresAt: number, allowFinish: boolean): Observable<ISharedEntity> {
        return this.getApiUrl(`/shareLinks/${shareId}`).pipe(
            mergeMap((url) => this.http.put<ISharedEntity>(url, { expiresAt, allowFinish }))
        );
    }

    public getSharedEntity(shareId: string, tenantId: string): Observable<ISharedEntity> {
        return this.getApiUrl(`/shareLinks/${tenantId}/${shareId}`).pipe(
            mergeMap((url) => this.http.get<ISharedEntity>(url))
        );
    }

    // Task Actions
    public shareLinkAction(shareId: string, action: string, tenantId: string): Observable<ISharedEntity> {
        return this.getApiUrl(`/shareLinks/${tenantId}/${shareId}/action`).pipe(
            mergeMap((url) => this.http.post<ISharedEntity>(url, { action }))
        );
    }

    public pickUpTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/pickUp`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public finishTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/finish`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public approveTaskInspection(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/approveInspection`).pipe(
            mergeMap((url) => this.http.put<ITask>(url, {}))
        );
    }

    public rejectTaskInspection(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/rejectInspection`).pipe(
            mergeMap((url) => this.http.put<ITask>(url, {}))
        );
    }

    public blockTask(taskId: string, blockReasonId: string, blockReasonNote: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/block`).pipe(
            mergeMap((url) =>
                this.http.put<ITask>(url, {
                    blockReasonId: blockReasonId || undefined,
                    blockReasonNote: blockReasonNote || undefined,
                })
            )
        );
    }

    public assignTask(taskId: string, userId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/assign`).pipe(mergeMap((url) => this.http.put<ITask>(url, { userId })));
    }

    public assignInspector(taskId: string, userId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/assigninspector`).pipe(
            mergeMap((url) => this.http.put<ITask>(url, { userId }))
        );
    }

    public unassignInspector(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/unassigninspector`).pipe(
            mergeMap((url) => this.http.put<ITask>(url, {}))
        );
    }

    public unassignTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/unassign`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public dropTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/drop`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public cancelTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/cancel`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public unblockTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/unblock`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public resumeTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/resume`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    public deleteTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}`).pipe(mergeMap((url) => this.http.delete<ITask>(url)));
    }

    public publishTask(taskId: string): Observable<ITask> {
        return this.getApiUrl(`/tasks/${taskId}/publish`).pipe(mergeMap((url) => this.http.put<ITask>(url, {})));
    }

    // Issue Actions
    public closeIssue(issueId: string): Observable<IIssue> {
        return this.getApiUrl(`/issues/${issueId}/close`).pipe(mergeMap((url) => this.http.put<IIssue>(url, {})));
    }

    // Job Sheet Actions
    public assignJobSheet(jobSheetId: string, userId: string): Observable<IJobSheet> {
        return this.getApiUrl(`/jobsheets/${jobSheetId}/assign`).pipe(
            mergeMap((url) => this.http.put<IJobSheet>(url, { userId }))
        );
    }

    public unassignJobSheet(jobSheetId: string): Observable<IJobSheet> {
        return this.getApiUrl(`/jobsheets/${jobSheetId}/unassign`).pipe(
            mergeMap((url) => this.http.put<IJobSheet>(url, {}))
        );
    }

    public assignJobSheetInspector(jobSheetId: string, userId: string): Observable<IJobSheet> {
        return this.getApiUrl(`/jobsheets/${jobSheetId}/assignInspector`).pipe(
            mergeMap((url) => this.http.put<IJobSheet>(url, { userId }))
        );
    }

    public unassignJobSheetInspector(jobSheetId: string): Observable<IJobSheet> {
        return this.getApiUrl(`/jobsheets/${jobSheetId}/unassignInspector`).pipe(
            mergeMap((url) => this.http.put<IJobSheet>(url, {}))
        );
    }

    public exportJobSheetsPDF(scheduleDate: number): Observable<Blob> {
        return this.getApiUrl('/jobsheets/export').pipe(
            mergeMap((url) => this.http.post(url, { scheduleDate }, { responseType: 'blob' })),
            tap((blob: Blob) => saveAs(blob, `planning-${scheduleDate}.pdf`))
        );
    }

    public postComment(
        itemType: string,
        itemId: string,
        comment?: string,
        images?: Array<Blob>
    ): Observable<ApiResponseStatus<IComment>> {
        const headers = new HttpHeaders({ 'ngsw-bypass': '' });
        const formData: FormData = new FormData();
        formData.append('itemType', itemType);
        formData.append('itemId', itemId);
        if (comment) {
            formData.append('comment', comment);
        }
        images?.forEach((image) => formData.append('images', image));

        return this.getApiUrl(`/comments`).pipe(
            mergeMap((url) =>
                this.http.post<IComment>(url, formData, {
                    reportProgress: true,
                    observe: 'events',
                    headers,
                })
            ),
            map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round((event.loaded / event.total) * 1000) / 1000;
                        return { status: 'progress', message: progress };

                    case HttpEventType.Response:
                        return { status: 'completed', message: event.body };
                    default:
                        return { status: 'ignored', message: `Ignored HTTPEventType enum: ${event.type}` };
                }
            })
        );
    }

    public listComment(itemType: string, itemId: string): Observable<Array<IComment>> {
        const queryParams = new HttpParams().append('itemId', itemId).append('itemType', itemType);
        return this.getApiUrl(`/comments`).pipe(
            mergeMap((url) => this.http.get<Array<IComment>>(url, { params: queryParams }))
        );
    }

    public getComment(commentId: string): Observable<IComment> {
        return this.getApiUrl(`/comments/${commentId}`).pipe(mergeMap((url) => this.http.get<IComment>(url)));
    }

    public getFilterPreset<Entity>(
        presetId: string,
        filterEntityType: FilterEntityType
    ): Observable<IFilterConfigurationModel<Entity>> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets/${presetId}`).pipe(
            mergeMap((url) => this.http.get<IFilterConfigurationModel<Entity>>(url))
        );
    }

    public postFilterPreset<Entity>(
        name: string,
        filterEntityType: FilterEntityType
    ): Observable<IFilterConfigurationModel<Entity>> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets`).pipe(
            concatMap((url) => this.http.post<IFilterConfigurationModel<Entity>>(url, { name: name }))
        );
    }

    public postFilterPresetSorting<Entity>(
        presetId: string,
        sort: IExternalSortingModel<keyof Entity | string>,
        filterEntityType: FilterEntityType
    ): Observable<ISortingModel<Entity>> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets/${presetId}/sorting`).pipe(
            mergeMap((url) => this.http.post<ISortingModel<Entity>>(url, sort))
        );
    }

    public postFilterPresetFilters(
        presetId: string,
        filterValues: IFilterModel,
        filterEntityType: FilterEntityType
    ): Observable<IFilterModel> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets/${presetId}/filter`).pipe(
            mergeMap((url) => this.http.post<IFilterModel>(url, filterValues))
        );
    }

    public patchFilterPreset<Entity>(
        name: string,
        presetId: string,
        filterEntityType: FilterEntityType
    ): Observable<IFilterConfigurationModel<Entity>> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets/${presetId}`).pipe(
            concatMap((url) => this.http.patch<IFilterConfigurationModel<Entity>>(url, { name: name }))
        );
    }

    public deleteFilterPreset<Entity>(
        filterPresetId: string,
        filterEntityType: FilterEntityType
    ): Observable<IFilterConfigurationModel<Entity>> {
        return this.getApiUrl(`${this.getEntityType(filterEntityType)}/filters/presets/${filterPresetId}`).pipe(
            mergeMap((url) => this.http.delete<IFilterConfigurationModel<Entity>>(url))
        );
    }

    public deleteFilterPresetFilters(
        filterPresetId: string,
        filterId: string,
        filterEntityType: FilterEntityType
    ): Observable<IFilterModel> {
        return this.getApiUrl(
            `${this.getEntityType(filterEntityType)}/filters/presets/${filterPresetId}/filter/${filterId}`
        ).pipe(mergeMap((url) => this.http.delete<IFilterModel>(url)));
    }

    public deleteFilterPresetSorting<Entity>(
        presetId: string,
        sortingId: string,
        filterEntityType: FilterEntityType
    ): Observable<ISortingModel<Entity>> {
        return this.getApiUrl(
            `${this.getEntityType(filterEntityType)}/filters/presets/${presetId}/sorting/${sortingId}`
        ).pipe(mergeMap((url) => this.http.delete<ISortingModel<Entity>>(url)));
    }

    public getBuildings(): Observable<IBuilding[]> {
        return this.getApiUrl('/buildings').pipe(mergeMap((url) => this.http.get<IBuilding[]>(url)));
    }

    public postBuilding(type: IBuildingPOST): Observable<IBuilding> {
        return this.getApiUrl('/buildings').pipe(mergeMap((url) => this.http.post<IBuilding>(url, type)));
    }

    public deleteBuilding(buildingId: string): Observable<IBuilding> {
        return this.getApiUrl(`/buildings/${buildingId}`).pipe(mergeMap((url) => this.http.delete<IBuilding>(url)));
    }

    public putBuilding(buildingId: string, building: IBuildingPUT): Observable<IBuilding> {
        return this.getApiUrl(`/buildings/${buildingId}`).pipe(
            mergeMap((url) => this.http.put<IBuilding>(url, building))
        );
    }

    private getEntityType(filterType: FilterEntityType): string {
        switch (filterType) {
            case FilterEntityType.Issue:
                return 'issues';
            case FilterEntityType.Task:
                return 'tasks';
            default:
                throw new Error(`invalid entity type: ${filterType}`);
        }
    }

    private static getArchivedDraftParams(includeArchived?: boolean, includeDraftOnly?: boolean): HttpParams {
        let params = new HttpParams();
        if (includeArchived) {
            params = params.append('includeArchived', 'true');
        }

        if (includeDraftOnly) {
            params = params.append('includeDraft', 'true');
        }

        return params;
    }
}
