import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ITenant, TENANT_KEY } from 'baseflow-auth';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { IUser } from 'shared';

import { DerivedStateValue, StateValue } from '../../../utils/state-value';
import { ISocketStatus } from '../socket.service';

@Injectable({ providedIn: 'root' })
export class AppStateService {
    //
    // States
    //
    public readonly user = new StateValue<IUser>();
    public readonly users = new StateValue<Array<IUser>>();
    public readonly activeTenantId = new StateValue<string>(null);
    public readonly socketStatus = new StateValue<ISocketStatus>({ status: 'DISCONNECTED' });

    //
    // Derived States
    //
    public readonly activeTenant = new DerivedStateValue<ITenant>(
        combineLatest([this.activeTenantId.stream.pipe(distinctUntilChanged()), this.user.stream]).pipe(
            map(([activeTenantId, user]) => user?.tenants.find((tenant) => tenant.id === activeTenantId))
        )
    );

    public readonly loggedInWithTenant = new DerivedStateValue<boolean>(
        combineLatest([this.user.stream, this.activeTenant.stream]).pipe(map(([user, tenant]) => !!user && !!tenant))
    );

    public constructor(storage: StorageMap) {
        storage
            .get<string>(TENANT_KEY, { type: 'string' })
            .subscribe((tenantId) => (this.activeTenantId.value = tenantId));
    }
}
