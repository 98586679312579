import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EntityFilters, ISortingModel, Pagination, invert } from 'shared';

import { DomService } from '../../../services/dom.service';
import { ITableData } from '../table/interfaces/table-data.interface';
import { ITableRow } from '../table/interfaces/table-row.interface';
import { DEFAULT_STEP, TableBaseComponent } from '../table/table-base.component';

export interface TableRowResponse<T> {
    pagination: Pagination;
    data: Array<ITableRow<T>>;
}

@Component({
    selector: 'app-table-server-side',
    templateUrl: './table-server-side.component.html',
    styleUrls: ['../table/table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [invert()],
})
export class TableServerSideComponent<T extends ITableData> extends TableBaseComponent<T> implements OnInit, OnChanges {
    private filterKeyMap: Record<string, string>;
    private reloadData$: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
    @Input()
    public dataLoader: (
        pagination: Pagination,
        filters: EntityFilters,
        sort: Array<ISortingModel<T>>,
        filterPreset?: string
    ) => Observable<TableRowResponse<T>>;

    @Input()
    public filterOptionsLoader: () => Observable<{ options: any; keyMap: Record<string, string> }>;

    public constructor(cdr: ChangeDetectorRef, domService: DomService, storage: StorageMap) {
        super(cdr, domService, storage);
    }

    public ngOnInit(): void {
        this.initBase();

        this.generateFilterOptions();

        combineLatest([this.pagination$, this.filter$, this.sorting$, this.reloadData$])
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                debounceTime(50),
                map(([pagination, filters, sorting]) => ({
                    pagination,
                    filters: this.mapFilters(filters),
                    filterPreset: this.filterPreset,
                    sorting: this.mapSorting(sorting),
                })),
                // Tell the dataloader to load the requested page with the requested filters.
                switchMap(({ pagination, filters, filterPreset, sorting }) =>
                    this.dataLoader(pagination, filters, sorting, filterPreset)
                ),
                tap(({ pagination, data }) => {
                    this.pagination.start = pagination.start ?? 0;
                    this.pagination.step = pagination.step ?? DEFAULT_STEP;
                    this.pagination.total = pagination.total;
                    this.data$.next(data);
                })
            )
            .subscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.onHeadingChanges(changes);
    }

    public reloadData(): void {
        this.reloadData$.next();
    }

    public resetFiltering(): void {
        super.resetFiltering();
    }

    protected subscribeData(): void {
        combineLatest([this.data$])
            .pipe(
                debounceTime(0),
                filter(([data]) => data !== null),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([data]) => {
                this.cdr.markForCheck();
                this.tableData = data;
            });
    }

    protected generateFilterOptions(): void {
        this.filterOptionsLoader()
            .pipe(
                take(1),
                tap(({ options, keyMap }) => {
                    this.filterOptions = options;
                    this.filterKeyMap = keyMap;
                })
            )
            .subscribe();
    }

    private mapFilters(filters: EntityFilters): EntityFilters {
        return Object.fromEntries(Object.entries(filters).map(([k, v]) => [this.filterKeyMap[k] ?? k, v]));
    }

    private mapSorting(sorting: Array<ISortingModel<T>>): Array<ISortingModel<T>> {
        return sorting.map(({ id, property, direction, order }) => ({
            id,
            property: (this.filterKeyMap[property as string] as keyof T) ?? property,
            direction,
            order,
        }));
    }
}
